body {
  background: var(--main-background-color);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --main-background-color: #ffffff;
  /* color-scheme: light dark; */
}

p, a {
  font-size: 14pt;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 800px) {
  .page-container {
    /* TODO: Improve */
    width: 90% !important;
  }
}

.page-container {
  width: 60%;
  overflow-x: hidden;
  margin: 0 auto;
  padding-bottom: 64px;
}

.page-container-padding-top {
  padding-top: 32px;
}