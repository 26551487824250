nav {
  align-items: center;
  background: #f1f1f9;
  box-shadow: 0px 2px 10px 0px #00000003;
  display: flex;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
}

.nav-links {
  display: flex;
  list-style: none;
}

.nav-links a {
  text-decoration: none;
  color: black;
  font-size: 24pt;
}

.logo {
  width: 90px;
  transition: 0.2s all;
}

.logo:hover {
  transform: scale(0.97, 0.97);
}